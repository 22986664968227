import React, { FC, useState } from 'react';
import {
  AppBar,
  Button,
  Unstable_Grid2 as Grid,
  Toolbar,
  Link,
  alpha,
  IconButton,
} from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { useLocation } from 'react-router-dom';

import { useSetImpersonationMutation } from 'api';
import { ClientLogo, Loaders } from 'components';
import { useCognitoUser, useCurrentUser, useNotify, useProtectedClient } from 'hooks';
import * as Links from 'Links';
import theme from 'theme';

import * as CSLinks from '../Links';
import Notifications from 'features/header/containers/Notifications';
import User from 'features/header/containers/User';

const styles = {
  link: {
    display: 'flex',
    alignItems: 'center',
  },
  clearButton: {
    color: 'common.white',
    background: 'error.main',
    '&:hover': {
      background: alpha(theme.palette.error.main, 0.7),
      borderColor: 'error.main',
    },
  },
} as const;

const Bar: FC = () => {
  const { pathname } = useLocation();
  const path = pathname ? pathname?.split('/client-success')[1] : '';
  const client = useProtectedClient();
  const notify = useNotify();
  const currentUser = useCurrentUser();
  const auth = useCognitoUser();
  const [notificationsLoaded, setNotificationsLoaded] = useState(false);

  const { mutate: setImpersonation, isLoading: isMutationLoading } = useSetImpersonationMutation({
    onError: notify.mutationError,
  });

  const isImpersonating = auth.id !== currentUser.id;

  function handleSetImpersonation() {
    setImpersonation(
      { id: auth.id, impersonate_user_id: null },
      {
        onSuccess: () => location.reload(),
      }
    );
  }

  return (
    <AppBar>
      <Toolbar>
        <Grid container spacing={2} alignItems="center" sx={{ width: '100vw' }}>
          <Grid>
            <Link sx={styles.link} component={Links.Index} underline="hover">
              <ClientLogo client={client} disableMargin height={24} />
            </Link>
          </Grid>
          <Grid>
            <Button
              component={CSLinks.ClientList}
              variant={path.includes('client') ? 'contained' : 'text'}
            >
              Clients
            </Button>
          </Grid>
          <Grid>
            <Button
              component={CSLinks.UserList()}
              variant={path.includes('user') ? 'contained' : 'text'}
            >
              Users
            </Button>
          </Grid>
          <Grid>
            <Button
              component={CSLinks.FeatureToggles}
              variant={path.includes('feature-toggles') ? 'contained' : 'text'}
            >
              Feature Toggles
            </Button>
          </Grid>
          <Grid>
            <Button
              component={CSLinks.Billing}
              variant={path.includes('billing') ? 'contained' : 'text'}
            >
              Billing
            </Button>
          </Grid>
          {isImpersonating && (
            <Grid>
              <Button sx={styles.clearButton} variant="contained" onClick={handleSetImpersonation}>
                {isMutationLoading ? <Loaders.Standard height={26} /> : 'Clear Impersonation'}
              </Button>
            </Grid>
          )}
          <Grid xs></Grid>
          <Grid>
            <Notifications onLoaded={() => setNotificationsLoaded(true)} />
            {!notificationsLoaded && (
              <IconButton size="large">
                <NotificationsNoneIcon />
              </IconButton>
            )}
          </Grid>
          <Grid>
            <User />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Bar;
