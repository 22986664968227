export const CELL_HEIGHT = 150;
export const IMAGE_HEIGHT = 110;

export const LINE_CHART_HEIGHT = 104;
export const PARTNER_LINE_CHART_HEIGHT = 200;

export const REACH_CHART_HEIGHT = 409;
export const REACH_CHART_LOADER_HEIGHT = 413;
export const PARTNER_REACH_CHART_HEIGHT = 239;
export const PARTNER_REACH_CHART_LOADER_HEIGHT = 323;
export const STAT_VALUE_HEIGHT = 27;
