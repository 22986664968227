import { lighten, darken } from '@mui/material/styles';

import theme from 'theme';

export function getColor(index: number) {
  const multiplier = index === 0 ? 1 : 0.1;
  return lighten(theme.palette.primary.main, multiplier * index);
}

export function getAreaColor(index: number) {
  const multiplier = index === 1 ? 0.3 : 1;
  return darken(theme.palette.primary.main, multiplier * index);
}
