import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Collapse, Paper, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { Form as FormikForm, Formik } from 'formik';
import { without } from 'lodash';

import {
  RoiCurrencyValuesInput,
  useGetUserRoiValuesQuery,
  useRoiClicksOverviewStatsQuery,
  useRoiEngagementsOverviewStatsQuery,
  useRoiImpressionsOverviewStatsQuery,
  useRoiReachOverviewStatsQuery,
  useUpdateUserRoiValuesMutation,
} from 'api';
import { DatePickers } from 'components';
import {
  useCapabilities,
  useCurrentUser,
  useDateRange,
  useNotify,
  useProtectedClient,
} from 'hooks';

import useStyles from '../analyticsStyles';
import { ChartLoader } from '../shared';
import { ROICurrencyInputSchema } from './validations';
import { Actions, NetworkInput, ROILineChart, Summary } from './ROI';
import { ROIChartType } from '../types';
import { ROI_CHART_EXCESS_HEIGHT, ROI_CHART_HEIGHT } from '../constants';
import { getColor } from '../helpers';
import theme from 'theme';

export const DEFAULT_CURRENCY_VALUES: RoiCurrencyValuesInput = {
  reach_twitter: 3.0,
  reach_linkedin: 13.75,
  clicks_twitter: 1.35,
  clicks_linkedin: 5.26,
  clicks_facebook: 1.07,
  engagements_twitter: 1.47,
  engagements_linkedin: 1.8,
  engagements_facebook: 3.15,
  impressions_twitter: 55.67,
  impressions_linkedin: 55.67,
};

const ROIOverviewStats: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles;
  const user = useCurrentUser();
  const client = useProtectedClient();
  const notify = useNotify();
  const { needs } = useCapabilities();

  const { startDate, setStartDate, endDate, setEndDate, dateRange, setDateRange } = useDateRange();
  const [collapsed, setCollapsed] = useState(false);

  const ROIChartItems = needs('Admin.Impressions.View.Access')
    ? Object.keys(ROIChartType)
    : without(Object.keys(ROIChartType), 'Impressions');

  const userROIValuesQueryResult = useGetUserRoiValuesQuery(
    {
      user_id: user.id,
    },
    {
      onError: notify.mutationError,
      onSuccess: data => {
        updateCurrencyValues(data.getUserROIValues);
      },
    }
  );

  const [currencyValues, setCurrencyValues] = useState(
    userROIValuesQueryResult?.data?.getUserROIValues || DEFAULT_CURRENCY_VALUES
  );

  const { mutate } = useUpdateUserRoiValuesMutation({
    onError: notify.mutationError,
  });

  const impressionsQueryResult = useRoiImpressionsOverviewStatsQuery(
    {
      client_id: client.id,
      start_date: startDate,
      end_date: endDate,
      twitter_currency_value: currencyValues.impressions_twitter,
      linkedin_currency_value: currencyValues.impressions_linkedin,
    },
    {
      onError: notify.queryError,
      enabled: !!userROIValuesQueryResult.data,
    }
  );

  const reachQueryResult = useRoiReachOverviewStatsQuery(
    {
      client_id: client.id,
      start_date: startDate,
      end_date: endDate,
      twitter_currency_value: currencyValues.reach_twitter,
      linkedin_currency_value: currencyValues.reach_linkedin,
    },
    {
      onError: notify.queryError,
      enabled: !!userROIValuesQueryResult.data,
    }
  );

  const clicksQueryResult = useRoiClicksOverviewStatsQuery(
    {
      client_id: client.id,
      start_date: startDate,
      end_date: endDate,
      twitter_currency_value: currencyValues.clicks_twitter,
      linkedin_currency_value: currencyValues.clicks_linkedin,
      facebook_currency_value: currencyValues.clicks_facebook,
    },
    {
      onError: notify.queryError,
      enabled: !!userROIValuesQueryResult.data,
    }
  );

  const engagementsQueryResult = useRoiEngagementsOverviewStatsQuery(
    {
      client_id: client.id,
      start_date: startDate,
      end_date: endDate,
      twitter_currency_value: currencyValues.engagements_twitter,
      linkedin_currency_value: currencyValues.engagements_linkedin,
      facebook_currency_value: currencyValues.engagements_facebook,
    },
    {
      onError: notify.queryError,
      enabled: !!userROIValuesQueryResult.data,
    }
  );

  const getROIQueryLoadingStatus = (chartType: ROIChartType) => {
    switch (chartType) {
      case ROIChartType.Reach:
        return reachQueryResult?.isLoading;
      case ROIChartType.Clicks:
        return clicksQueryResult?.isLoading;
      case ROIChartType.Engagements:
        return engagementsQueryResult?.isLoading;
      case ROIChartType.Impressions:
        return impressionsQueryResult?.isLoading;
    }
  };

  const getROIQueryResult = (chartType: ROIChartType) => {
    switch (chartType) {
      case ROIChartType.Reach:
        return reachQueryResult?.data?.roiReachOverviewStats;
      case ROIChartType.Clicks:
        return clicksQueryResult?.data?.roiClicksOverviewStats;
      case ROIChartType.Engagements:
        return engagementsQueryResult?.data?.roiEngagementsOverviewStats;
      case ROIChartType.Impressions:
        return impressionsQueryResult?.data?.roiImpressionsOverviewStats;
    }
  };

  function updateCurrencyValues(values: RoiCurrencyValuesInput) {
    setCurrencyValues({
      reach_twitter: values.reach_twitter
        ? values.reach_twitter
        : DEFAULT_CURRENCY_VALUES.reach_twitter,
      reach_linkedin: values.reach_linkedin
        ? values.reach_linkedin
        : DEFAULT_CURRENCY_VALUES.reach_linkedin,
      clicks_twitter: values.clicks_twitter
        ? values.clicks_twitter
        : DEFAULT_CURRENCY_VALUES.clicks_twitter,
      clicks_linkedin: values.clicks_linkedin
        ? values.clicks_linkedin
        : DEFAULT_CURRENCY_VALUES.clicks_linkedin,
      clicks_facebook: values.clicks_facebook
        ? values.clicks_facebook
        : DEFAULT_CURRENCY_VALUES.clicks_facebook,
      engagements_twitter: values.engagements_twitter
        ? values.engagements_twitter
        : DEFAULT_CURRENCY_VALUES.engagements_twitter,
      engagements_linkedin: values.engagements_linkedin
        ? values.engagements_linkedin
        : DEFAULT_CURRENCY_VALUES.engagements_linkedin,
      engagements_facebook: values.engagements_facebook
        ? values.engagements_facebook
        : DEFAULT_CURRENCY_VALUES.engagements_facebook,
      impressions_twitter: values.impressions_twitter
        ? values.impressions_twitter
        : DEFAULT_CURRENCY_VALUES.impressions_twitter,
      impressions_linkedin: values.impressions_linkedin
        ? values.impressions_linkedin
        : DEFAULT_CURRENCY_VALUES.impressions_linkedin,
    });
  }

  function handleSubmit(values: RoiCurrencyValuesInput) {
    updateCurrencyValues(values);
    mutate({ user_id: user.id, roi_currency_values: values });
  }

  function renderROIInputActionBar() {
    return (
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ borderTop: `1px solid ${theme.palette.grey[200]}` }}
        marginTop={2}
      >
        <Grid>
          <DatePickers.Range
            dateRange={dateRange}
            startDate={startDate}
            endDate={endDate}
            onSetStart={setStartDate}
            onSetEnd={setEndDate}
            onSetRange={setDateRange}
            transparent
          />
        </Grid>
        <Grid>
          <Actions collapsed={collapsed} onToggleCollapse={setCollapsed} />
        </Grid>
      </Grid>
    );
  }

  function renderChart(chartType: ROIChartType, index: number) {
    const isUserValuesLoading = userROIValuesQueryResult.isLoading;
    const isROIQueryLoading = getROIQueryLoadingStatus(chartType);
    const hasData = getROIQueryResult(chartType);

    if (isUserValuesLoading || isROIQueryLoading) {
      return (
        <Grid key={index} xs={3}>
          <ChartLoader type="chartLine" height={ROI_CHART_HEIGHT + ROI_CHART_EXCESS_HEIGHT} />
        </Grid>
      );
    }

    if (!hasData) {
      return null;
    }

    const { data, summary } = getROIQueryResult(chartType);
    const config = [
      {
        label: chartType,
        dataKey: 'currency',
      },
      {
        label: `Previous ${chartType}`,
        dataKey: 'previous_currency',
      },
    ];

    return (
      <Grid key={index} xs={3}>
        <Summary chartType={chartType} summary={summary} />
        <ROILineChart height={ROI_CHART_HEIGHT} data={data} config={config} />
      </Grid>
    );
  }

  function renderNetworkInput(chartType: ROIChartType, index: number) {
    const isLoading = getROIQueryLoadingStatus(chartType);
    const result = getROIQueryResult(chartType);
    const hideFacebook = chartType === ROIChartType.Reach || chartType === ROIChartType.Impressions;

    return (
      <Grid key={index} xs={3}>
        <NetworkInput
          hideFacebook={hideFacebook}
          chartType={chartType}
          loading={isLoading}
          result={result}
        />
      </Grid>
    );
  }

  function renderLegend() {
    const currentBgColor = getColor(0);
    const previousBgColor = getColor(1);
    const currentBg = { background: currentBgColor };
    const previousBg = {
      background: `repeating-linear-gradient(to right, ${previousBgColor} 0 6px, transparent 6px 9px)`,
    };

    return (
      <Grid container spacing={3}>
        <Grid container alignItems="center" spacing={1}>
          <Grid>
            <Box sx={classes.solid} style={currentBg} />
          </Grid>
          <Grid>
            <Typography display="inline">{t('components:currentPeriod')}</Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={1}>
          <Grid>
            <Box sx={classes.dashed} style={previousBg} />
          </Grid>
          <Grid>
            <Typography display="inline">{t('components:previousPeriod')}</Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function renderROIInputs() {
    if (userROIValuesQueryResult.isLoading) {
      return null;
    }

    return (
      <Collapse in={collapsed} timeout="auto" unmountOnExit sx={{ marginTop: 3, padding: 2 }}>
        <Grid container alignItems="flex-start" justifyContent="center" spacing={2}>
          {ROIChartItems.map(renderNetworkInput)}
        </Grid>
      </Collapse>
    );
  }

  return (
    <Fragment>
      <Typography paragraph variant="h5" color="textSecondary">
        {t(`analytics:roi_header`)}
      </Typography>
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={currencyValues}
        validationSchema={ROICurrencyInputSchema()}
      >
        <FormikForm>
          <Paper sx={{ padding: 2, paddingBottom: 0 }}>
            <Grid container alignItems="center">
              {ROIChartItems.map(renderChart)}
            </Grid>

            {renderLegend()}
            {renderROIInputs()}
            {renderROIInputActionBar()}
          </Paper>
        </FormikForm>
      </Formik>
    </Fragment>
  );
};

export default ROIOverviewStats;
