import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Paper, Tooltip, Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { useGetUserReachGraphDataQuery } from 'api';
import { useDateRange, useSocialConnections } from 'hooks';
import {
  Buttons,
  DatePickers,
  ConditionalNumberTooltip,
  LoaderComponents,
  Numeric,
} from 'components';

import { AreaChart, ChartLoader, IAreaChartConfig } from 'features/admin/components/Analytics';
import { STAT_VALUE_HEIGHT } from '../constants';
import ActivityChange from 'features/admin/components/Analytics/shared/ActivityChange';
interface Props {
  chartHeight: number;
  loaderHeight: number;
}
const styles = {
  number: {
    fontWeight: 600,
    fontSize: 20,
  },
};

const TotalReach: FC<Props> = ({ chartHeight, loaderHeight }) => {
  const { startDate, setStartDate, endDate, setEndDate, dateRange, setDateRange } = useDateRange();
  const { linkedinAccounts } = useSocialConnections();
  const { t } = useTranslation();

  const {
    data: reachData,
    isLoading,
    isFetched,
  } = useGetUserReachGraphDataQuery({
    start_date: startDate,
    end_date: endDate,
  });

  const linkedInAccountToRefresh = linkedinAccounts.find(a => a.expires_soon);

  function renderLegend() {
    return (
      <Grid container spacing={1} alignItems="center">
        <Grid>
          <Typography display="inline">LinkedIn</Typography>
        </Grid>
        <Grid>
          <Buttons.Refresh account={linkedInAccountToRefresh} />
        </Grid>
      </Grid>
    );
  }
  function renderValue(value: number) {
    return (
      <ConditionalNumberTooltip value={value}>
        <Typography variant="h4" sx={styles.number}>
          {isLoading ? (
            <LoaderComponents.Block width={STAT_VALUE_HEIGHT} height={STAT_VALUE_HEIGHT} />
          ) : (
            <Numeric value={value} />
          )}
        </Typography>
      </ConditionalNumberTooltip>
    );
  }

  function renderTitle() {
    return (
      <Tooltip title={t('help:admin_external_followers')}>
        <Typography variant="h6">{t('components:yourNetwork')}</Typography>
      </Tooltip>
    );
  }

  function renderCountAndChange() {
    if (isLoading && !isFetched) {
      return renderValue(null);
    }

    const { summary } = reachData.getUserReachGraphData;
    return (
      <Grid container alignItems="center" spacing={1}>
        <Grid>{renderValue(summary.total_reach.count)}</Grid>
        <Grid>
          <ActivityChange countChange={summary.total_reach.change} />
        </Grid>
      </Grid>
    );
  }

  function renderChart() {
    if (isLoading && !isFetched) {
      return (
        <Fragment>
          <ChartLoader type="chartArea" height={loaderHeight} />
        </Fragment>
      );
    }
    const { summary, data } = reachData.getUserReachGraphData || {};

    const config: IAreaChartConfig[] = [
      {
        name: t('components:twitterFollowers'),
        dataKey: 'twitter_reach',
        value: summary.twitter_reach,
      },
      {
        name: t('components:linkedinConnections'),
        dataKey: 'linkedin_reach',
        value: summary.linkedin_reach,
        legendComponent: linkedInAccountToRefresh ? renderLegend : undefined,
      },
    ];

    return (
      <Fragment>
        <Box paddingBottom={0.5}>
          <AreaChart height={chartHeight} data={data} config={config} />
        </Box>
      </Fragment>
    );
  }

  return (
    <Paper>
      <Box padding={3}>
        <Box marginBottom={2}>{renderTitle()}</Box>
        <Typography display="inline" variant="subtitle2" color="textSecondary">
          {t('common:follower_plural')}
        </Typography>
        {renderCountAndChange()}
        {renderChart()}
      </Box>
      <Divider />
      <Box paddingLeft={1}>
        <DatePickers.Range
          dateRange={dateRange}
          startDate={startDate}
          endDate={endDate}
          onSetStart={setStartDate}
          onSetEnd={setEndDate}
          onSetRange={setDateRange}
          transparent
        />
      </Box>
    </Paper>
  );
};

export default TotalReach;
