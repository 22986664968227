import React, { FC, ReactNode, useRef, useState, useLayoutEffect, PureComponent } from 'react';
import { Box, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import {
  Area,
  AreaChart as ReChartsAreaChart,
  CartesianGrid,
  Legend,
  LegendProps,
  ResponsiveContainer,
  Tooltip as ReChartsTooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { IndividualStat, ReachDailyStat } from 'api';
import { formatStat } from 'helpers';

import { getAreaColor } from '../helpers';
import { Tooltip } from '../shared';

export interface IAreaChartConfig {
  name: string;
  dataKey: string;
  value: IndividualStat;
  legendComponent?: ReactNode;
}

interface IProps {
  height: number;
  data: ReachDailyStat[];
  config: IAreaChartConfig[];
}

class CustomizedAxisTick extends PureComponent<{
  x: number;
  y: number;
  stroke: string;
  payload: { value: string };
}> {
  render() {
    const { x, y, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dx={0} textAnchor="end" fill="#666">
          {payload.value}
        </text>
      </g>
    );
  }
}

const AreaChart: FC<IProps> = ({ height, data, config }) => {
  const legendValues = getLegendValues();

  const gridColor = 'grey.300';
  const margin = { top: 0, right: 0, bottom: 0, left: 0 };
  const wrapperStyle = { bottom: 0 };

  const containerRef = useRef(null);

  const [containerWidth, setContainerWidth] = useState(0);

  useLayoutEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        if (containerWidth !== undefined) {
          setContainerWidth(containerWidth);
        }
      }
    };
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  function getLegendValues() {
    const values: number[] = [];
    config.forEach(({ value }) => {
      values.push(value.count);
    });
    return values;
  }

  function renderLegendItem({ value }: { value: number }, index: number) {
    const legendBgColor = getAreaColor(index);

    return (
      <Grid key={index}>
        <Grid container alignItems="center" justifyContent="space-between" paddingLeft={0}>
          <Grid>
            <Grid container alignItems="center" spacing={1}>
              <Grid>
                <Box
                  sx={{
                    backgroundColor: legendBgColor,
                    width: 18,
                    height: 18,
                    borderRadius: '50%',
                  }}
                />
              </Grid>
              <Grid>
                {config[index].legendComponent || <Typography display="inline">{value}</Typography>}
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Typography>{formatStat(legendValues[index], '')}</Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function renderLegend({ payload }: LegendProps) {
    return (
      <Grid container direction="column" spacing={1}>
        {payload.map(renderLegendItem)}
      </Grid>
    );
  }

  return (
    <div ref={containerRef}>
      <ResponsiveContainer height={height}>
        <ReChartsAreaChart data={data} margin={margin}>
          <CartesianGrid stroke={gridColor} vertical={false} />
          <XAxis
            dataKey="calendar_day"
            tickLine={false}
            axisLine={{ stroke: gridColor }}
            tickMargin={10}
            tickFormatter={() => ''}
          />
          <YAxis
            tickLine={false}
            orientation="right"
            axisLine={{ stroke: gridColor }}
            tick={props => <CustomizedAxisTick {...props} x={containerWidth} />}
          />{' '}
          <Legend wrapperStyle={wrapperStyle} content={renderLegend} />
          <ReChartsTooltip content={<Tooltip />} />
          {config.map((entry, index) => (
            <Area
              key={entry.dataKey}
              type="monotone"
              dataKey={entry.dataKey}
              name={entry.name}
              stackId="1"
              stroke={getAreaColor(index)}
              fill={getAreaColor(index)}
            />
          ))}
        </ReChartsAreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AreaChart;
