import React, { FC, useCallback, useEffect, useState } from 'react';
import { Descendant, Editor, Range, Transforms } from 'slate';
import { Editable, RenderPlaceholderProps, Slate } from 'slate-react';
import { isHotkey, isKeyHotkey } from 'is-hotkey';
import { useFormikContext, useField } from 'formik';

import { escapeFormat, toggleMark } from './helpers';
import { SlateValue } from './types';
import { serialize } from './serialization';
import { HOTKEYS } from './constants';
import { EmojiSearch, Toolbar, Element, Leaf } from './Components';
import { useEmojiContext, useSelectionContext } from './Contexts';
import theme from 'theme';
import { useBlockList, useReleases } from 'hooks';
import { findWordMatch } from 'features/sharing/helpers';
import { getHighlightedBlocklistRanges } from './helpers';
import { serializeToPlainText } from './serialization';
import { useDebounce } from 'use-debounce';

interface Props {
  editor: Editor;
  fieldName: string;
  blockListFieldName?: string;
  placeholder: string;
  slateValue: SlateValue[];
  renderPlaceholder?(props: RenderPlaceholderProps): JSX.Element;
  onFocus(): void;
  onChange(descendant: Descendant[]): void;
}

const styles = {
  textField: {
    minHeight: '200px',
  },
} as const;

const SlateInstance: FC<Props> = props => {
  const {
    editor,
    fieldName,
    blockListFieldName,
    placeholder,
    renderPlaceholder,
    slateValue,
    onChange,
    onFocus,
  } = props;
  const { setFieldValue } = useFormikContext();
  const [editorFocused, setEditorFocused] = useState(false);

  const renderElement = useCallback(elementProps => <Element {...elementProps} />, []);
  const renderLeaf = useCallback(leafProps => <Leaf {...leafProps} />, []);

  const { callbacks: selectionCallbacks } = useSelectionContext();
  const { callbacks: emojiCallbacks } = useEmojiContext();
  const [field, meta] = useField(fieldName);

  const releases = useReleases();
  const { terms } = useBlockList();

  const hasBlocklist = releases.includes('blocklist');
  const [blocklistedWords] = useDebounce(findWordMatch(terms, field.value), 200);

  useEffect(() => {
    if (hasBlocklist && blockListFieldName && editorFocused) {
      setFieldValue(fieldName, serializeToPlainText(slateValue));
    }
  }, [slateValue]);

  useEffect(() => {
    if (hasBlocklist && blockListFieldName && editorFocused) {
      setFieldValue(blockListFieldName, blocklistedWords);
    }
  }, [blocklistedWords]);

  const highlightBlockListTerms = useCallback(
    ([node, path]): Range[] => {
      const ranges: Range[] = [];

      const matchedWords = blocklistedWords;

      if (!hasBlocklist || !matchedWords?.length || !meta.error) {
        return ranges;
      }

      return getHighlightedBlocklistRanges(node, path, matchedWords);
    },
    [blockListFieldName, blocklistedWords]
  );

  function handleFocus() {
    setEditorFocused(true);
    onFocus();
  }

  function handleBlur() {
    const serialized = slateValue.map(serialize).join('');
    setFieldValue(fieldName, serialized);
    setEditorFocused(false);
  }

  function handleKeyDown(event: React.KeyboardEvent) {
    const { selection } = editor;

    if (event.key === 'Enter') {
      event.stopPropagation();
      escapeFormat(editor, event);
      return;
    }

    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event as unknown as KeyboardEvent)) {
        event.preventDefault();
        const mark = HOTKEYS[hotkey];
        toggleMark(editor, mark);
        return;
      }
    }

    if (selection && Range.isCollapsed(selection)) {
      const { nativeEvent } = event;
      if (isKeyHotkey('left', nativeEvent)) {
        event.preventDefault();
        Transforms.move(editor, { unit: 'offset', reverse: true });
        return;
      }
      if (isKeyHotkey('right', nativeEvent)) {
        event.preventDefault();
        Transforms.move(editor, { unit: 'offset' });
        return;
      }
    }
  }

  function handleRenderPlaceholder({ attributes, children }: RenderPlaceholderProps) {
    const materialUIAttributes = {
      ...attributes,
      style: {
        ...attributes.style,
        opacity: theme.palette.action.disabledOpacity,
      },
    };

    return renderPlaceholder ? (
      renderPlaceholder({ attributes: materialUIAttributes, children })
    ) : (
      <span {...materialUIAttributes}>{children}</span>
    );
  }

  return (
    <Slate
      editor={editor}
      value={slateValue}
      onChange={value => {
        onChange(value);
        emojiCallbacks.onEditorChange(value);
      }}
    >
      <Editable
        spellCheck
        style={styles.textField}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        placeholder={placeholder}
        decorate={highlightBlockListTerms}
        renderPlaceholder={handleRenderPlaceholder}
        onBlur={() => {
          selectionCallbacks.onEditorBlur();
          handleBlur();
        }}
        onFocus={handleFocus}
        onKeyDown={event => {
          emojiCallbacks.onEditorKeyDown(event);
          handleKeyDown(event);
        }}
        onKeyUp={emojiCallbacks.onEditorKeyUp}
      />
      <Toolbar />
      <EmojiSearch />
    </Slate>
  );
};

SlateInstance.defaultProps = {
  blockListFieldName: '',
};

export default SlateInstance;
