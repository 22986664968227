import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Paper, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { DatePickers } from 'components';

import { useUserActivityStatsQuery } from 'api';
import { useCapabilities, useCurrentUser, useDateRange, useNotify } from 'hooks';

import StatsItem from './StatsItem';

interface Props {
  showInternal: boolean;
  chartHeight: number;
}

const Stats: FC<Props> = ({ showInternal, chartHeight }) => {
  const { t } = useTranslation();
  const notify = useNotify();
  const currentUser = useCurrentUser();
  const { startDate, setStartDate, endDate, setEndDate, dateRange, setDateRange } = useDateRange();
  const { needs } = useCapabilities();

  const hasImpressions = needs('Admin.Impressions.View.Access');

  const { data, isLoading } = useUserActivityStatsQuery(
    { user_id: currentUser.id, start_date: startDate, end_date: endDate },
    {
      onError: notify.queryError,
    }
  );

  const { userActivityStats } = data || {};

  function renderStat(dataKey: string, label: string, sm?: number, md?: number, lg?: number) {
    return (
      <Grid xs={12} sm={sm || 6} md={md || 6} lg={lg || 3}>
        <StatsItem
          dataKey={dataKey}
          label={label}
          isLoading={isLoading}
          stats={userActivityStats}
          height={chartHeight}
        />
      </Grid>
    );
  }

  return (
    <Paper>
      <Box paddingLeft={3} paddingTop={3} marginBottom={2}>
        <Typography variant="h6">
          {t(isLoading ? 'help:activity_external_shares' : 'components:myShares')}
        </Typography>
      </Box>
      <Box paddingLeft={3} paddingRight={3} paddingBottom={3}>
        <Grid container spacing={3}>
          {renderStat('shares', t('common:share_plural'))}
          {hasImpressions && renderStat('impressions', t('common:impression_plural'))}
          {renderStat('clicks', t('common:click_plural'))}
          {renderStat('engagements', t('common:engagement_plural'))}
        </Grid>
      </Box>
      <Divider />
      {showInternal && (
        <Fragment>
          <Box paddingLeft={3} paddingTop={2} marginBottom={2}>
            <Typography variant="h6">
              {t(isLoading ? 'help:activity_internal_posts' : 'components:myActivity')}
            </Typography>
          </Box>
          <Box paddingLeft={3} paddingRight={3} paddingBottom={3}>
            <Grid container spacing={3}>
              <Grid xs={12} sm={6} padding={1}>
                {renderStat('posts', t('common:post_plural'), 12, 12, 12)}
              </Grid>
              <Grid xs={12} sm={6} padding={1}>
                {renderStat('reactions', t('common:like_plural'), 12, 12, 12)}
              </Grid>
            </Grid>
          </Box>
        </Fragment>
      )}
      <Divider />
      <Box paddingLeft={1}>
        <DatePickers.Range
          dateRange={dateRange}
          startDate={startDate}
          endDate={endDate}
          onSetStart={setStartDate}
          onSetEnd={setEndDate}
          onSetRange={setDateRange}
          transparent
        />
      </Box>
    </Paper>
  );
};

export default Stats;
