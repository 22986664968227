import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Paper,
  Tooltip as MuiTooltip,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Line, LineChart, ResponsiveContainer, Tooltip, TooltipProps } from 'recharts';
import moment from 'moment/moment';
import { STAT_VALUE_HEIGHT } from '../constants';
import { UserActivityStat } from 'api';
import {
  ConditionalNumberTooltip,
  LoaderComponents,
  Loaders,
  Numeric,
  TextTransform,
} from 'components';
import theme from 'theme';
import ActivityChange from 'features/admin/components/Analytics/shared/ActivityChange';

const styles = {
  text: theme.mixins.absoluteCenter,
  tooltip: {
    padding: theme.spacing(),
  },
  number: {
    fontWeight: 600,
    fontSize: 20,
  },
};

interface Props {
  dataKey: string;
  label: string;
  isLoading: boolean;
  stats: UserActivityStat;
  height: number;
}

const StatsItem: FC<Props> = ({ height, dataKey, label, isLoading, stats }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { daily_stats = [], percent_change = {}, summary_stats = {} } = stats || {};

  function renderLineChartEmptyState() {
    return (
      <Fragment>
        <Loaders.LineChart placeholderMode height={height} />
        <Typography align="center" variant="subtitle2" sx={styles.text}>
          {t('emptyStates:activityStatChart')}
        </Typography>
      </Fragment>
    );
  }

  function renderLineChart() {
    return (
      <ResponsiveContainer height={height}>
        <LineChart data={daily_stats}>
          <Line
            type="monotone"
            dataKey={dataKey}
            stroke={theme.palette.primary.main}
            strokeWidth={2}
            dot={false}
          />
          <Tooltip content={renderTooltip} />
        </LineChart>
      </ResponsiveContainer>
    );
  }

  function renderTooltip({ active, payload }: TooltipProps<number, string>) {
    if (!active || !payload?.length) {
      return null;
    }

    const [currentPayload] = payload;
    const { name, value } = currentPayload;
    const date = moment(currentPayload.payload.calendar_day).format('ll');

    return (
      <Paper sx={styles.tooltip}>
        <Typography>{date}</Typography>
        <Typography>
          <TextTransform transform="capitalize">{name}</TextTransform>: {value}
        </Typography>
      </Paper>
    );
  }

  function renderValue(value: number) {
    return (
      <ConditionalNumberTooltip value={value}>
        <Typography variant="h4" sx={styles.number}>
          {isLoading ? (
            <LoaderComponents.Block width={STAT_VALUE_HEIGHT} height={STAT_VALUE_HEIGHT} />
          ) : (
            <Numeric value={value} />
          )}
        </Typography>
      </ConditionalNumberTooltip>
    );
  }

  function renderChart() {
    if (isLoading) {
      return <Loaders.LineChart height={height} />;
    }

    if (!isLoading && !daily_stats.length) {
      return renderLineChartEmptyState();
    }

    return renderLineChart();
  }

  return (
    <Fragment>
      <MuiTooltip title={t(`help:activity_${dataKey}`)}>
        <Typography display="inline" variant="subtitle2" color="textSecondary">
          {label}
        </Typography>
      </MuiTooltip>
      <Grid container alignItems="center" spacing={1}>
        <Grid>{renderValue(summary_stats[dataKey])}</Grid>
        <Grid>
          <ActivityChange countChange={percent_change[dataKey]} />
        </Grid>
      </Grid>
      <Box paddingTop={2} paddingBottom={2} position="relative">
        {renderChart()}
      </Box>
    </Fragment>
  );
};

export default StatsItem;
