import React, { FC } from 'react';
import { Typography } from '@mui/material';

interface IProps {
  countChange: number;
}

const ActivityChange: FC<IProps> = ({ countChange }) => {
  if (!countChange) {
    return null;
  }

  if (countChange === 0) {
    return (
      <Typography variant="subtitle1" color="textSecondary">
        {'--'}%
      </Typography>
    );
  }

  if (countChange > 0) {
    return (
      <Typography variant="subtitle1" color="primary">
        {countChange}%
      </Typography>
    );
  }

  return (
    <Typography variant="subtitle1" color="error">
      {Math.abs(countChange)}%
    </Typography>
  );
};

export default ActivityChange;
