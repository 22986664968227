import React, { FC, ReactNode } from 'react';
import { GridSpacing, Unstable_Grid2 as Grid } from '@mui/material';
import { columnSplitToGridSizes } from '../../helpers';

interface Props {
  left: ReactNode;
  right: ReactNode;
  header?: ReactNode;
  headerSpacing?: GridSpacing;
  mainSpacing?: GridSpacing;
  desktopSplit?: string;
}

const AppMain2Column: FC<Props> = props => {
  const { desktopSplit, header, headerSpacing, mainSpacing, left, right } = props;
  const [desktopLeft, desktopRight] = columnSplitToGridSizes(desktopSplit);
  const spacing = header ? headerSpacing : 0;

  return (
    <Grid container spacing={spacing}>
      {header && <Grid xs>{header}</Grid>}
      <Grid
        xs={12}
        container
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={mainSpacing}
      >
        <Grid xs lg={desktopLeft}>
          {left}
        </Grid>
        <Grid
          xs
          lg={desktopRight}
          sx={{
            display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' },
          }}
        >
          <div>{right}</div>
        </Grid>
      </Grid>
    </Grid>
  );
};

AppMain2Column.defaultProps = {
  desktopSplit: '9/3',
  header: null,
  headerSpacing: 3,
  mainSpacing: 3,
};

export default AppMain2Column;
