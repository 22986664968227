import React, { FC, Fragment } from 'react';
import { Divider, Unstable_Grid2 as Grid } from '@mui/material';

import { EmptyState, PostImage } from 'components';
import { SharesItemFragment } from 'api';
import { useNotify, useSharesItems } from 'hooks';

import { IMAGE_HEIGHT } from '../constants';

import ListLoader from './ListLoader';
import SharesActions from './SharesActions';

import { Account, Date, Post, ShareCopy } from './Partials';

const styles = {
  date: {
    minHeight: 48,
  },
  divider: {
    marginTop: 2,
  },
  item: {
    paddingLeft: 2,
    paddingRight: 2,
  },
} as const;

const SharesScheduled: FC = () => {
  const notify = useNotify();

  const { results, isEmpty, isInitialLoad } = useSharesItems(
    {
      scheduled: true,
      group_by_session: false,
      data: {
        limit: 25,
      },
    },
    {
      onError: notify.queryError,
    }
  );

  if (isInitialLoad) {
    return <ListLoader />;
  }

  if (isEmpty) {
    return <EmptyState name="scheduled" />;
  }

  function renderRow(shareItem: SharesItemFragment) {
    return (
      <Grid container alignItems="center" justifyContent="center" key={shareItem.id}>
        <Grid xs={12} sx={styles.date} container alignItems="center" paddingX={2}>
          <Grid xs>
            <Date time={shareItem.share_at} />
          </Grid>
          <Grid>
            <SharesActions shareItem={shareItem} />
          </Grid>
        </Grid>
        <Grid xs={12} container paddingX={2}>
          <Grid paddingRight={2} paddingBottom={2}>
            <PostImage size={IMAGE_HEIGHT} content={shareItem.content} rounded roundedRadius={8} />
          </Grid>
          <Grid xs container>
            <Grid xs={12}>
              <Account shareItem={shareItem} />
            </Grid>
            <Grid>
              <ShareCopy item={shareItem} />
            </Grid>
            <Grid xs={12}>
              <Post content={shareItem.content} />
            </Grid>
          </Grid>
        </Grid>

        <Grid xs={12}>
          <Divider sx={styles.divider} />
        </Grid>
      </Grid>
    );
  }

  return <Fragment>{results.map(renderRow)}</Fragment>;
};

export default SharesScheduled;
